// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Afm from "../app-core/Afm.bs.js";
import * as React from "react";
import * as Router from "common/src/Router.bs.js";
import * as NitroAd from "./NitroAd.bs.js";
import * as ScreenAtom from "common/src/atoms/ScreenAtom.bs.js";

function SidebarAd$Stack(Props) {
  var rail = Props.rail;
  Afm.use(undefined);
  var screenSize = ScreenAtom.useNoSSR(undefined);
  return React.createElement("div", {
              className: "hidden lg:block self-start mt-5 static top-[108px] xl:mt-[340px]"
            }, React.createElement("div", {
                  className: "lg:min-w-[160px] lg:min-h-[600px] xxl:min-w-[300px]"
                }, React.createElement(NitroAd.make, {
                      format: "sticky-stack",
                      reportPosition: "bottom-right",
                      id: "na-side-stack-" + rail,
                      sizes: screenSize !== 0 ? (
                          screenSize >= 3 ? [] : [[
                                160,
                                600
                              ]]
                        ) : [
                          [
                            300,
                            600
                          ],
                          [
                            160,
                            600
                          ],
                          [
                            300,
                            250
                          ],
                          [
                            320,
                            480
                          ],
                          [
                            320,
                            50
                          ],
                          [
                            320,
                            100
                          ]
                        ],
                      stickyStackLimit: 15,
                      stickyStackSpace: 1.25,
                      stickyStackOffset: 108
                    })));
}

var Stack = {
  make: SidebarAd$Stack
};

function SidebarAd(Props) {
  var rail = Props.rail;
  var router = Router.use(undefined);
  var useStack = React.useMemo((function () {
          if (rail === "right") {
            return router.asPath.startsWith("/team-compositions");
          } else {
            return false;
          }
        }), [
        router.asPath,
        rail
      ]);
  Afm.use(undefined);
  var screenSize = ScreenAtom.useNoSSR(undefined);
  if (useStack) {
    return React.createElement(SidebarAd$Stack, {
                rail: rail
              });
  } else {
    return React.createElement("div", {
                className: "hidden lg:block self-start mt-5 sticky top-[108px] xl:mt-[340px]"
              }, React.createElement("div", {
                    className: "lg:min-w-[160px] lg:min-h-[600px] xxl:min-w-[300px]"
                  }, React.createElement(NitroAd.make, {
                        reportPosition: "bottom-right",
                        id: "na-sticky-side-" + rail,
                        sizes: screenSize !== 0 ? (
                            screenSize >= 3 ? [] : [[
                                  160,
                                  600
                                ]]
                          ) : [
                            [
                              300,
                              600
                            ],
                            [
                              160,
                              600
                            ],
                            [
                              300,
                              250
                            ],
                            [
                              320,
                              480
                            ],
                            [
                              320,
                              50
                            ],
                            [
                              320,
                              100
                            ]
                          ]
                      })));
  }
}

var make = SidebarAd;

export {
  Stack ,
  make ,
  
}
/* Afm Not a pure module */
