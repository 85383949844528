// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var ctx = React.createContext(/* Set11 */4);

function makeProps(value, children, param) {
  return {
          value: value,
          children: children
        };
}

var make = ctx.Provider;

var make$1 = React.memo(make);

var Provider = {
  makeProps: makeProps,
  make: make$1
};

function num(set) {
  switch (set) {
    case /* Set85 */0 :
        return 85;
    case /* Set9 */1 :
        return 90;
    case /* Set95 */2 :
        return 95;
    case /* Set10 */3 :
        return 10;
    case /* Set11 */4 :
        return 110;
    case /* Set12 */5 :
        return 120;
    case /* Set36 */6 :
        return 36;
    
  }
}

function num2(set) {
  switch (set) {
    case /* Set85 */0 :
        return 85;
    case /* Set9 */1 :
        return 90;
    case /* Set95 */2 :
        return 95;
    case /* Set10 */3 :
        return 100;
    case /* Set11 */4 :
        return 110;
    case /* Set12 */5 :
        return 120;
    case /* Set36 */6 :
        return 36;
    
  }
}

function fromNum(num) {
  if (num < 37) {
    if (num !== 10) {
      if (num >= 36) {
        return /* Set36 */6;
      } else {
        return /* Set11 */4;
      }
    } else {
      return /* Set10 */3;
    }
  }
  if (num >= 111) {
    if (num !== 120) {
      return /* Set11 */4;
    } else {
      return /* Set12 */5;
    }
  }
  if (num < 85) {
    return /* Set11 */4;
  }
  switch (num) {
    case 85 :
        return /* Set85 */0;
    case 90 :
        return /* Set9 */1;
    case 95 :
        return /* Set95 */2;
    case 100 :
        return /* Set10 */3;
    case 86 :
    case 87 :
    case 88 :
    case 89 :
    case 91 :
    case 92 :
    case 93 :
    case 94 :
    case 96 :
    case 97 :
    case 98 :
    case 99 :
    case 101 :
    case 102 :
    case 103 :
    case 104 :
    case 105 :
    case 106 :
    case 107 :
    case 108 :
    case 109 :
    case 110 :
        return /* Set11 */4;
    
  }
}

function use(param) {
  var s = React.useContext(ctx);
  return num(s);
}

function useT(param) {
  return React.useContext(ctx);
}

export {
  ctx ,
  Provider ,
  num ,
  num2 ,
  fromNum ,
  use ,
  useT ,
  
}
/* ctx Not a pure module */
