import { useState, forwardRef, memo } from "react";
import { use as useSet } from "common/src/data/TftSet.bs";
import {
  usePath as useBasePath,
  usePath4 as useBasePath4,
} from "common/src/atoms/CdnAtom.bs";
import { getSrcSet, getSrcSet2 } from "./ImgCore";
import { useT2 } from "../bindings/Translation.bs";

const dir = (set: number) => {
  switch (set) {
    case 95:
      return "items_s95";
    case 10:
    case 36:
    case 100:
    case 110:
    case 120:
      return "items_s10";
    default:
      return "items";
  }
};

const getSrc = (
  newPath: boolean,
  basePath: string,
  set: number,
  itemId: string,
) => {
  if (newPath) {
    return `${basePath}/${dir(set)}/${itemId.replace(
      /TFT(.*?)_(Item|Consumable)_(.+)/,
      "$1$3",
    )}.png`;
  } else {
    switch (set) {
      case 85: {
        return `${basePath}/items/80_${itemId}.jpg`;
      }
      default:
        return "";
    }
  }
};

type Props = {
  itemId: string;
  size: number;
  sizeSm?: number;
  sizeMd?: number;
  sizeLg?: number;
  className?: string;
  rounded?: string;
};

const ItemImage = memo(
  forwardRef(
    (
      {
        itemId,
        size,
        sizeSm,
        sizeMd,
        sizeLg,
        className = "",
        rounded = "",
        ...rest
      }: Props,
      ref,
    ) => {
      const [usePlaceholder, setUsePlaceholder] = useState(false);
      const itemsT = useT2("items");
      const set = useSet();
      const bp = useBasePath();
      const bp4 = useBasePath4();
      const newPath = Number.isNaN(Number(itemId));
      const basePath = newPath ? bp4 : bp;

      const src = getSrc(newPath, basePath, set, usePlaceholder ? "_" : itemId);
      const [sizes, srcSet, sizeClass] = newPath
        ? getSrcSet2(src, size, sizeSm, sizeMd, sizeLg)
        : getSrcSet(src, size, sizeSm, sizeMd, sizeLg);

      return (
        <img
          loading="lazy"
          decoding="async"
          alt={itemsT(itemId)}
          src={src + `?${newPath ? "w" : "width"}=${size}`}
          className={`aspect-square ${className} ${rounded} ${sizeClass}`}
          // @ts-ignore
          ref={ref}
          sizes={sizes}
          srcSet={srcSet}
          onError={(_) => setUsePlaceholder(true)}
          {...rest}
        />
      );
    },
  ),
);

export default ItemImage;
