import { useMemo } from "react";
import { usePath, usePath4 } from "common/src/atoms/CdnAtom.bs.js";
import StarIcon from "@mui/icons-material/Star";

// let getVariableHashKey = variableName => {
//   "{" ++ Fnv1a.ritoHash(variableName) ++ "}"
// }

type Icon =
  | "ap"
  | "ad"
  | "as"
  | "hp"
  | "ar"
  | "mr"
  | "gold"
  | "range"
  | "crit"
  | "crit_mult"
  | "mana"
  | "star"
  | "headliner"
  | "da"
  | "dr"
  | "sv";

type Part =
  | ["text", string]
  | ["icon", Icon]
  | ["nbsp"]
  | ["br"]
  | ["brbr"]
  | ["spell_passive", string]
  | ["spell_active", string]
  | ["ad_scaling", string]
  | ["ap_scaling", string]
  | ["hp_scaling", Array<Part>]
  | ["level_scaling", Array<Part>]
  | ["physical_damage", Array<Part>]
  | ["magic_damage", Array<Part>]
  | ["true_damage", Array<Part>]
  | ["tft_bonus", Array<Part>]
  | ["tft_keyword", string]
  | ["tft_item_rules", Array<Part>]
  | ["bold", string]
  | ["row", Array<Part>]
  | ["scale_ad", Array<Part>]
  | ["scale_ap", Array<Part>]
  | ["headliner", Array<Part>]
  | ["bling_bonus", Array<Part>]
  | ["radiant_item_bonus", Array<Part>]
  | ["shadow_item_bonus", Array<Part>]
  | ["multi", Array<Part>];

const parseRegex =
  /(&nbsp;|<TFTHeadliner>.+?<\/TFTHeadliner>|<spellActive enabled=TFT10_BlingActive alternate=rules>.+?<\/spellActive>|<row>.+?<\/row>|<li>.+?<li>.+?<br>|<TFTHighlight>.*?<\/TFTHighlight>|<TFTBonus.*?>.*?<\/TFTBonus>|<tftbonus>.+?<\/tftbonus>|<TFTRadiantItemBonus>.+?<\/TFTRadiantItemBonus>|<spellActive>.+?<\/spellActive>|<spellPassive>.+?<\/spellPassive>|<br><br>|<br>|<b>.+?<\/b>|<tftbold>.+?<\/tftbold>|<tftstrong>.+?<\/tftstrong>|<tftitemrules>.+?<\/tftitemrules>|<TFTShadowItemBonus>.+?<\/TFTShadowItemBonus>|<TFTShadowItemPenalty>.+?<\/TFTShadowItemPenalty>|<specialRules>.+?<\/specialRules>|<rules>.+?<\/rules>|<scaleAD>.+?<\/scaleAD>|<scaleHealth>.+?<\/scaleHealth>|<scaleAP>.+?<\/scaleAP>|<physicalDamage>.+?<\/physicalDamage>|<magicDamage>.+?<\/magicDamage>|<trueDamage>.+?<\/trueDamage>|<scaleLevel>.+?<\/scaleLevel>|<TFTKeyword>.+?<\/TFTKeyword>|<keyword>.+?<\/keyword>|%i:scaleSV%|%i:scaleDR%|%i:scaleDA%|%i:set10Headliner%|%i:star%|%i:goldCoins%|%i:scaleRange%|%i:scaleCrit%|%i:scaleCritMult%|%i:scaleAS%|%i:scaleAP%?|%i:scaleHealth%|%i:scaleMana%|%i:scaleAD%|%i:scaleArmor%|%i:scaleMR%)/gm;
export const parseDesc = (desc: string): Array<Part> => {
  if (!desc) {
    return [];
  }
  const parts = desc.split(parseRegex).map((part): Part => {
    switch (part) {
      case "<br><br>":
        return ["brbr"];
      case "<br>":
        return ["br"];
      case "&nbsp;":
        return ["nbsp"];
      case "%i:goldCoins%":
        return ["icon", "gold"];
      case "%i:star%":
        return ["icon", "star"];
      case "%i:scaleRange%":
        return ["icon", "range"];
      case "%i:scaleCrit%":
        return ["icon", "crit"];
      case "%i:scaleCritMult%":
        return ["icon", "crit_mult"];
      case "%i:scaleAS%":
        return ["icon", "as"];
      case "%i:scaleAP%":
        return ["icon", "ap"];
      case "%i:scaleAP":
        return ["icon", "ap"];
      case "%i:scaleDA%":
        return ["icon", "da"];
      case "%i:scaleDR%":
        return ["icon", "dr"];
      case "%i:scaleSV%":
        return ["icon", "sv"];
      case "%i:scaleHealth%":
        return ["icon", "hp"];
      case "%i:scaleMana%":
        return ["icon", "mana"];
      case "%i:scaleAD%":
        return ["icon", "ad"];
      case "%i:scaleArmor%":
        return ["icon", "ar"];
      case "%i:scaleMR%":
        return ["icon", "mr"];
      case "%i:set10Headliner%":
        return ["icon", "headliner"];
      default: {
        if (part.startsWith("<row>")) {
          return ["row", parseDesc(part.slice(5, part.length - 6))];
        } else if (
          part.startsWith("<TFTBonus") ||
          part.startsWith("<tftbonus")
        ) {
          const tagCloseIdx = part.indexOf(">");
          return [
            "tft_bonus",
            parseDesc(part.slice(tagCloseIdx + 1, part.length - 11)),
          ];
        } else if (part.startsWith("<TFTHighlight>")) {
          return ["tft_bonus", parseDesc(part.slice(14, part.length - 15))];
        } else if (part.startsWith("<TFTRadiantItemBonus>")) {
          return [
            "radiant_item_bonus",
            parseDesc(part.slice(21, part.length - 22)),
          ];
        } else if (part.startsWith("<spellActive>")) {
          return ["spell_passive", part.slice(13, part.length - 14)];
        } else if (part.startsWith("<spellPassive>")) {
          return ["spell_active", part.slice(14, part.length - 15)];
        } else if (part.startsWith("<magicDamage>")) {
          return ["magic_damage", parseDesc(part.slice(13, part.length - 14))];
        } else if (part.startsWith("<physicalDamage>")) {
          return [
            "physical_damage",
            parseDesc(part.slice(16, part.length - 17)),
          ];
        } else if (part.startsWith("<trueDamage>")) {
          return ["true_damage", parseDesc(part.slice(12, part.length - 13))];
        } else if (part.startsWith("<scaleHealth>")) {
          return ["hp_scaling", parseDesc(part.slice(13, part.length - 14))];
        } else if (part.startsWith("<scaleLevel>")) {
          return ["level_scaling", parseDesc(part.slice(12, part.length - 13))];
        } else if (part.startsWith("<tftstrong>")) {
          return ["bold", part.slice(11, part.length - 12)];
        } else if (part.startsWith("<tftbold>")) {
          return ["bold", part.slice(9, part.length - 10)];
        } else if (part.startsWith("<b>")) {
          return ["bold", part.slice(3, part.length - 4)];
        } else if (part.startsWith("<tftitemrules>")) {
          return [
            "tft_item_rules",
            parseDesc(part.slice(14, part.length - 15)),
          ];
        } else if (part.startsWith("<specialRules>")) {
          return ["spell_passive", part.slice(14, part.length - 15)];
        } else if (part.startsWith("<rules>")) {
          return ["tft_item_rules", parseDesc(part.slice(7, part.length - 8))];
        } else if (part.startsWith("<scaleAD>")) {
          return ["scale_ad", parseDesc(part.slice(9, part.length - 10))];
        } else if (part.startsWith("<scaleAP>")) {
          return ["scale_ap", parseDesc(part.slice(9, part.length - 10))];
        } else if (part.startsWith("<TFTKeyword>")) {
          return ["tft_keyword", part.slice(12, part.length - 13)];
        } else if (part.startsWith("<keyword>")) {
          return ["tft_keyword", part.slice(9, part.length - 10)];
        } else if (part.startsWith("<TFTHeadliner>")) {
          return ["headliner", parseDesc(part.slice(14, part.length - 15))];
        } else if (part.startsWith("<spellActive")) {
          return ["bling_bonus", parseDesc(part.slice(55, part.length - 14))];
        } else if (part.startsWith("<TFTShadowItemBonus>")) {
          return [
            "shadow_item_bonus",
            parseDesc(part.slice(20, part.length - 21)),
          ];
        } else if (part.startsWith("<TFTShadowItemPenalty>")) {
          return [
            "shadow_item_bonus",
            parseDesc(part.slice(22, part.length - 23)),
          ];
        } else if (part.startsWith("<li>")) {
          const desc = part.slice(4, part.length - 4);
          return [
            "multi",
            desc.split("<li>").map((desc) => ["row", parseDesc(desc)] as Part),
          ];
        } else {
          return ["text", part];
        }
      }
    }
  });

  return parts;
};

const textDiff = (
  text1: string,
  text2: string,
): [[string, string, string], [string, string, string]] => {
  // Count from front and back characters that are equal, middle is the diff part then
  const s1 = text1.toLowerCase().split("");
  const s2 = text2.toLowerCase().split("");

  let frontDone = false;
  let backDone = false;

  const s1l = s1.length;
  const s2l = s2.length;

  let frontCount = 0;
  let backCount = 0;

  for (let idx = 0; idx < s1l && idx < s2l; idx++) {
    if (!frontDone) {
      if (s1[idx] === s2[idx]) {
        frontCount += 1;
      } else {
        frontDone = true;
      }
    }

    if (!backDone) {
      if (s1[s1l - 1 - idx] === s2[s2l - 1 - idx]) {
        backCount += 1;
      } else {
        backDone = true;
      }
    }
  }

  backCount = Math.min(backCount, Math.min(s1l, s2l) - frontCount);

  return [
    [
      text1.slice(0, frontCount),
      text1.slice(frontCount, s1l - backCount),
      text1.slice(s1l - backCount),
    ],
    [
      text2.slice(0, frontCount),
      text2.slice(frontCount, s2l - backCount),
      text2.slice(s2l - backCount),
    ],
  ];
};

export const diffParts = (
  parts1: Array<Part>,
  parts2: Array<Part>,
): [Array<Part>, Array<Part>, Array<number>, Array<number>] => {
  let idx1 = 0;
  let idx2 = 0;
  let partIdx1 = 0;
  let partIdx2 = 0;
  const highlights1 = [];
  const highlights2 = [];
  const outParts1 = [];
  const outParts2 = [];
  while (idx1 < parts1.length && idx2 < parts2.length) {
    // TODO at some point, extra checks for new stuff that's added
    // console.log("cmp", parts1[idx1], parts2[idx2]);
    if (JSON.stringify(parts1[idx1]) != JSON.stringify(parts2[idx2])) {
      const p1 = parts1[idx1];
      const p2 = parts2[idx2];

      if (p1[0] === "text" && p2[0] === "text") {
        const [[f1, m1, b1], [f2, m2, b2]] = textDiff(p1[1], p2[1]);
        highlights1.push(partIdx1 + 1);
        highlights2.push(partIdx2 + 1);
        outParts1.push(["text", f1] as Part);
        outParts1.push(["text", m1] as Part);
        outParts1.push(["text", b1] as Part);
        outParts2.push(["text", f2] as Part);
        outParts2.push(["text", m2] as Part);
        outParts2.push(["text", b2] as Part);

        idx1 += 1;
        idx2 += 1;
        partIdx1 += 3;
        partIdx2 += 3;
      } else {
        highlights1.push(partIdx1);
        highlights2.push(partIdx2);
        outParts1.push(p1);
        outParts2.push(p2);
        idx1 += 1;
        idx2 += 1;
        partIdx1 += 1;
        partIdx2 += 1;
      }
    } else {
      outParts1.push(parts1[idx1]);
      outParts2.push(parts2[idx2]);
      idx1 += 1;
      idx2 += 1;
      partIdx1 += 1;
      partIdx2 += 1;
    }
  }

  while (idx1 < parts1.length) {
    highlights1.push(partIdx1);
    outParts1.push(parts1[idx1]);
    idx1 += 1;
    partIdx1 += 1;
  }

  while (idx2 < parts2.length) {
    highlights2.push(partIdx2);
    outParts2.push(parts2[idx2]);
    idx2 += 1;
    partIdx2 += 1;
  }

  return [outParts1, outParts2, highlights1, highlights2];
};

const iconConfig: {
  [key: string]: [string, string];
} = {
  ap: ["ap", "Ability power"],
  ad: ["ad", "Attack damage"],
  as: ["as", "Attack speed"],
  hp: ["hp", "Health"],
  ar: ["armor", "Armor"],
  mr: ["mr", "Magic resist"],
  gold: ["gold", "Gold"],
  range: ["range", "Range"],
  crit: ["crit_chance", "Critical strike change"],
  crit_mult: ["crit_damage", "Critical strike damage"],
  da: ["da", "Damage Amplification"],
  dr: ["dr", "Damage Reduction"],
  sv: ["sv", "Omnivamp"],
  mana: ["mana", "Mana"],
  star: ["star", "Star Level"],
  headliner: ["star", "Headliner"],
};
const iconSize = "14";
export const Icon = ({ icon }: { icon: string }) => {
  const basePath = usePath4();
  const [ic, alt] = iconConfig[icon];

  if (ic === "star") {
    return <StarIcon fontSize="inherit" />;
  } else {
    return (
      <img
        className="inline-block mt-[-2px]"
        alt={alt}
        width={iconSize}
        height={iconSize}
        src={`${basePath}/general/${ic}.png?w=${iconSize}`}
      />
    );
  }
};

function Part({ part, highlight }: { part: Part; highlight: boolean }) {
  const [type, value] = part;
  switch (type) {
    case "nbsp":
      return <>{"\u00A0"}</>;
    case "br":
      return <div className="h-[6px]" />;
    case "brbr":
      return <div className="h-[10px]" />;
    case "icon":
      return <Icon icon={value} />;
    case "spell_passive":
    case "spell_active":
      return <span className="font-medium text-gold-11">{value}</span>;
    case "row":
      return (
        <div>
          <DescParts parts={value} />
        </div>
      );
    case "multi":
      return (
        <div className="flex flex-col pl-1 gap-1 py-1">
          {value.map((parts, idx) => (
            <Part key={idx} part={parts} highlight={highlight} />
          ))}
        </div>
      );
    case "tft_bonus":
      return (
        <span className="text-yellow-11">
          <DescParts parts={value} />
        </span>
      );
    case "physical_damage":
      return (
        <span className="text-tomato-11">
          <DescParts parts={value} />
        </span>
      );
    case "true_damage":
      return (
        // light yellow-3
        <span className="text-[#fffbd1]">
          <DescParts parts={value} />
        </span>
      );
    case "scale_ad":
      return (
        <span className="text-tomato-11">
          <DescParts parts={value} />
        </span>
      );
    case "scale_ap":
      return (
        <span className="text-sky-11">
          <DescParts parts={value} />
        </span>
      );
    case "magic_damage":
      return (
        <span className="text-sky-11">
          <DescParts parts={value} />
        </span>
      );
    case "tft_item_rules":
      return (
        <span className="text-white2 italic">
          <DescParts parts={value} />
        </span>
      );
    case "bold":
      return <span className="font-medium">{value}</span>;
    case "hp_scaling":
      return (
        <span className="text-green-9">
          <DescParts parts={value} />
        </span>
      );
    case "radiant_item_bonus":
      return (
        // light yellow-6
        <span className="text-[#efd36c]">
          <DescParts parts={value} />
        </span>
      );
    case "shadow_item_bonus":
      return (
        <span className="text-tertiary-11">
          <DescParts parts={value} />
        </span>
      );
    case "level_scaling":
      return (
        <span className="text-yellow-11">
          <DescParts parts={value} />
        </span>
      );
    case "tft_keyword":
      return <span className="text-bronze-11">{value}</span>;
    case "headliner":
      return <DescParts parts={value} />;
    case "bling_bonus":
      return <DescParts parts={value} />;

    default:
      return <span>{value}</span>;
  }
}

export const DescParts = ({
  parts,
  highlightParts,
}: {
  parts: Array<Part>;
  highlightParts?: Array<number>;
}) => {
  return (
    <>
      {parts.map((part, idx) => {
        const highlight = highlightParts ? highlightParts.includes(idx) : true;
        return (
          <span key={idx} className={highlight ? "" : "opacity-60"}>
            <Part part={part} highlight={highlight} />
          </span>
        );
      })}
    </>
  );
};

export const Desc = ({
  desc,
  className,
}: {
  desc: string;
  className?: string;
}) => {
  let parts = useMemo(() => parseDesc(desc), [desc]);

  return (
    <div className={"leading-tight " + (className || "")}>
      <DescParts parts={parts} />
    </div>
  );
};
