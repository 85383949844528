import { useEffect, useRef, useLayoutEffect } from "react";
import { isProd } from "common/src/Env.bind";

export default function NitroAd({
  id,
  demo,
  format,
  sizes,
  rail,
  railOffsetTop,
  railOffsetBottom,
  renderVisibleOnly,
  anchor,
  anchorBgColor,
  anchorPersistClose,
  reportPosition,
  mediaQuery,
  stickyStackLimit,
  stickyStackSpace,
  stickyStackOffset,
}) {
  const el = useRef(null);
  const key = id + " " + sizes.map((size) => size.join(",")).join(" ");

  useLayoutEffect(() => {
    el.current.id = id;
  }, [id]);

  const runAd = () => {
    if (window.afm) {
      return;
    }
    if (window.initAdIds.includes(id)) {
      window.initAdIds = window.initAdIds.filter((i) => i !== id);
      return;
    }

    let sizes2 = sizes && sizes.map((v) => v.map((v2) => v2.toString()));
    const cfg = {
      format,
      demo: demo || !isProd,
      // demo,
      anchor,
      anchorPersistClose,
      anchorBgColor,
      rail,
      railOffsetTop,
      railOffsetBottom,

      stickyStackLimit: stickyStackLimit || undefined,
      stickyStackSpace: stickyStackSpace || undefined,
      stickyStackOffset: stickyStackOffset || undefined,
      railCollisionWhitelist: ["*"],
      refreshLimit: 0,
      refreshTime: 30,
      renderVisibleOnly: renderVisibleOnly || false,
      refreshVisibleOnly: true,
      sizes: sizes2,
      report: {
        enabled: true,
        wording: "Report Ad",
        position: reportPosition || "top-right",
      },
      mediaQuery,
    };

    // console.log("Create", id, cfg);
    window["nitroAds"].createAd(id, cfg);
  };

  useEffect(() => {
    runAd();
  }, [key]);

  const style =
    id === "na-side-stack-right"
      ? {
          height: "380vh",
        }
      : {};

  return (
    <div
      ref={(ref) => (el.current = ref)}
      style={style}
      key={key}
      id={id}
      dangerouslySetInnerHTML={{ __html: "" }}
      suppressHydrationWarning
    />
  );
}
