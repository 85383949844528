export const removeHpTempTraits = (hp) => {
  hp.info.traits = hp.info.traits.filter((trait) => {
    !trait.name.startsWith("TFTEvent5YR");
  });

  return hp;
};

export const removeMatchTempTraits = (mi) => {
  mi.info.participants.forEach((hp) => {
    hp.traits = hp.traits.filter((trait) => {
      return !trait.name.startsWith("TFTEvent5YR");
    });
  });

  return mi;
};
